import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../page/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('../page/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('../page/welcome/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'establishments',
    loadChildren: () => import('../page/establishments/establishments.module').then(m => m.EstablishmentsPageModule)
  },
  {
    path: 'category-list',
    loadChildren: () => import('../page/category-list/category-list.module').then(m => m.CategoryListPageModule)
  },
  {
    path: 'menu-item-list',
    loadChildren: () => import('../page/menu-item-list/menu-item-list.module').then(m => m.MenuItemListPageModule)
  },
  {
    path: 'menu-item-update',
    loadChildren: () => import('../page/menu-item-update/menu-item-update.module').then(m => m.MenuItemUpdatePageModule)
  },
  {
    path: 'category-update',
    loadChildren: () => import('../page/category-update/category-update.module').then(m => m.CategoryUpdatePageModule)
  },
  {
    path: 'establishment-view',
    loadChildren: () => import('../page/establishment-view/establishment-view.module').then(m => m.EstablishmentViewPageModule)
  },
  {
    path: 'establishment-earnings',
    loadChildren: () => import('../page/establishment-earnings/establishment-earnings.module').then( m => m.EstablishmentEarningsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('../page/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'documents-upload',
    loadChildren: () => import('../page/documents-upload/documents-upload.module').then(m => m.DocumentsUploadPageModule)
  },
  {
    path: 'restaurant-profile',
    loadChildren: () => import('../page/restaurant-profile/restaurant-profile.module').then(m => m.RestaurantProfilePageModule)
  },
  {
    path: 'tariff-configuration',
    loadChildren: () => import('../page/tariff-configuration/tariff-configuration.module').then(m => m.TariffConfigurationPageModule)
  },
  {
    path: 'promotion-list',
    loadChildren: () => import('../page/promotion-list/promotion-list.module').then(m => m.PromotionListPageModule)
  },
  {
    path: 'promotion-view',
    loadChildren: () => import('../page/promotion-view/promotion-view.module').then(m => m.PromotionViewPageModule)
  },
  {
    path: 'table-list',
    loadChildren: () => import('../page/table-list/table-list.module').then( m => m.TableListPageModule)
  },
  {
    path: 'bookings-list',
    loadChildren: () => import('../page/bookings-list/bookings-list.module').then(m => m.BookingsListPageModule)
  },
  {
    path: 'available-times',
    loadChildren: () => import('../page/available-times/available-times.module').then( m => m.AvailableTimesPageModule)
  },
  {
    path: 'restaurant-owners',
    loadChildren: () => import('../page/restaurant-owners/restaurant-owners.module').then(m => m.RestaurantOwnersPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('../page/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('../page/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'deals',
    loadChildren: () => import('../page/deals/deals.module').then(m => m.DealsPageModule)
  },
  {
    path: 'deal-view',
    loadChildren: () => import('../page/deal-view/deal-view.module').then(m => m.DealViewPageModule)
  },
  {
    path: 'establishment-option-selection',
    loadChildren: () => import('../page/establishment-option-selection/establishment-option-selection.module').then(m => m.EstablishmentOptionSelectionPageModule)
  },
  {
    path: 'employee-management',
    loadChildren: () => import('../page/employee-management/employee-management.module').then( m => m.EmployeeManagementPageModule)
  },
  {
    path: 'order-management',
    loadChildren: () => import('../page/order-management/order-management.module').then(m => m.OrderManagementPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
